/* FUENTES */
@import url("https://fonts.googleapis.com/css2?family=Montserrat+Alternates:wght@800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat+Alternates&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@200&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Spicy+Rice&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;700&display=swap");
/*probador */
@import url('https://fonts.googleapis.com/css2?family=Texturina:ital,opsz,wght@0,12..72,100..900;1,12..72,100..900&display=swap');
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background:linear-gradient(to bottom,#ADD8E6,orange 90%); */
  overflow-x: hidden;
background-color: orange;
}

body::-webkit-scrollbar {
  width: 8px;
  background-color: #849B4E;
}
body::-webkit-scrollbar-thumb {
  background: orange;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
