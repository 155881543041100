@import url("https://fonts.googleapis.com/css2?family=Gruppo&display=swap");
/*estilos que afectan todo la aplicación*/
a:hover {
  text-decoration: none;
}
.ReactModal__Overlay{
  z-index: 10000;
}
ol li{
  text-decoration: none;
}
ol{
  margin: 0;
}
/* Contenedor de aplicación completa */
.APP {
  height: auto;
  width: 100vw;
  min-width: 100vw;
  max-width: 100vw;
  overflow: hidden;
}

/* HEADER */
header {
  width: 100%;
  height: 20vh;
  display: grid;
  grid-template-columns: 20% 1fr;
  grid-template-rows: 100%;
  box-sizing: border-box;
  position: fixed;
  z-index: 1000;
  /* agregados */
  transition: 0.6s;
  top: 0;
  left: 0;
}
header.sticky {
  height: 10vh;
  z-index: 1000;
  backdrop-filter: blur(5px);
  background: linear-gradient(to right, rgb(20, 20, 49), rgba(128, 128, 128, 0.333) 45%);
}

header.sticky .container-menu {
  display: flex;
  align-items: center;
}
header.sticky .container-menu ul {
  top: 20px;
}
.container-menu {
  position: relative;
}
.container-menu ul {
  position: absolute;
  top: 60%;
  display: flex;
  justify-content: space-around;
  width: 80%;
  height: auto;
  list-style-type: none;
}
.container-menu ul li {
  cursor: pointer;
}

.container-menu ul li a:not(#lista-desplegada .sublista a),
.container-menu ul li span {
  width: auto;
  height: auto;
  font-family: "Gruppo", sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 1.6rem;
  opacity: 0;
  animation: aparecer-menu 1s linear;
  animation-delay: 2s;
  animation-fill-mode: forwards;
  /* agregados */
  color: #2b2723;
}
header.sticky .container-menu ul li a:not(#lista-desplegada .sublista a),
header.sticky .container-menu ul li span {
color: rgb(212, 212, 212);
}
.container-menu ul li a:not(#lista-desplegada .sublista a):hover,
.container-menu ul li span {
  letter-spacing: 1px;
  transition: 0.3s;
}
header .container-logo img {
  height: 80%;
  width: auto;
  transition: 0.6s;
}
.container-logo {
  display: flex;
  justify-content: center;
  align-items: center;
}

.container-logo a {
  height: 100%;
  width: auto;
  display: flex;
  align-items: center;
}
#proposito {
  /* border: solid #6a4928 3px;
  border-radius: 20px; */
  height: 400px;
  width: 400px;
  position: absolute;
  right: -10%;
  bottom: -400px;
  /* background-color: #d2eac3; */
  background-image: url('Multimedia/papel.webp');
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 40px;
  font-size: 1.1rem;
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-weight: 600;
  box-shadow: 0px 10px 20px 5px rgba(0, 0, 0, 0.83);
}
#proposito p{
  color: black;
  backdrop-filter: blur(5px);
}
#lista-desplegada {
  display: flex;
  flex-direction: column;
  height: auto;
  width: auto;
  position: absolute;
  top: 100%;
  background-color: #d2eac3b5;
  font-weight: 600;
  font-family: Arial, Helvetica, sans-serif;
  padding: 0;
  border-radius: 5px;
  border: solid #2b2723 1px;
  overflow: hidden;
  box-sizing: border-box;
}
#lista-desplegada .sublista {
  border: dotted #2b2723 1px;
  padding: 10px;
}
#lista-desplegada .sublista a {
  color: #2b2723;
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
  font-size: 1.2rem;
}
#lista-desplegada .sublista a:hover {
  color: #ffffff;
  transition: 0.1s;
}


/* MAIN */
main {
  height: auto;
  width: 100%;
}
/* FOOTER */
footer {
  width: 100%;
  height: 40vh;
}

/* ANIMACIONES */

/* menu */
@keyframes aparecer-menu {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Header */





/* RESPONSIVE */
@media (max-width: 767px) {
  /* estilo provisorio (debemos quitarlos)  */
  
 
  nav ,header.sticky .container-menu{
    display: none;
  }
  /* HEADER */
  header{
    width: 95%;
    margin: 2.5% 2.5% 0 2.5%;
    border-radius: 20px;
    grid-template-columns: min-content 1fr;
  }
  header.sticky{
    border: solid grey 2px;

  }
  .container-logo{
    height: 100%;

  }
  .container-logo a {
    height: 100%;
    width: auto;
    display: flex;
    align-items: center;
  }
  .container-logo a .Logo{
    height: 80%;

  }
  /* MAIN */
  
}
/* tabletas */
@media (min-width:767px) and (max-width:1024px) {
  nav ,header.sticky .container-menu{
    display: none;
  }
  header{
    width: 95%;
    max-width: 95vw;
    margin: 2.5% 2.5% 0 2.5%;
    border-radius: 20px;
    grid-template-columns: min-content 1fr;
    border: solid rgba(128, 128, 128, 0.242) 2px;
    backdrop-filter: blur(5px);
    background-color: rgba(128, 128, 128, 0.246);

  }
  header.sticky{
    border: none;

  }
  

}
