.container-owl {
  display: none;
}
@media (max-width: 767px) {
  .container-owl {
    display: block;
    height: auto;
    width: 100vw;
    background-color: #B5D1A1;
  }
  .link-owl-responsive h1 {
    font-size: 2.7rem;
    text-align: center;
    border: solid rgb(8, 40, 5) 3px;
    color: rgb(241, 241, 241);
    font-family: "Spicy Rice", serif;
    font-weight: 400;
    font-style: normal;
    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
      1px 1px 0 #000;
      margin: 0;
      padding: 10px ;
      clip-path: polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%);
      background-color: #113f0d;

  }
  .link-owl-responsive img{
    border: #113f0d solid 10px;
    box-sizing: border-box;
  }
  .owl-nav {
    background-color: #B5D1A1;
    display: flex;
    justify-content: right;
    position: relative;
    height: 70px;
    margin-top: 20px;
  }

  .owl-nav button span {
    visibility: hidden;
  }
  /* marca */
  .owl-prev::before {
    content: "";
    position: absolute;
    top: 0;
    right: 30%;
    width: 70px;
    height: 100%;
    background: #849b4e;
    border: solid 3px #113f0d;
    background-image: url("../../../../Multimedia/previous-svgrepo-com.svg");
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 50%;
  }
  .owl-next::before {
    content: "";
    position: absolute;
    top: 0;
    right: 5%;
    width: 70px;
    height: 100%;
    background: rgb(110, 34, 34);
    background: #849b4e;
    border: solid 3px #113f0d;
    background-image: url("../../../../Multimedia/next-svgrepo-com.svg");
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 50%;
  }

  .owl-dots {
    display: none;
  }

  .owl-item {
    height: auto;
  }
}
