
@import url("https://fonts.googleapis.com/css2?family=Montserrat+Alternates:wght@800&display=swap");
.articulo {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #d2eac3;
}
.grid-container-articulo {
  width: 90%;
  height: 80%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 70% 1fr;
  grid-template-areas:
    "titulo foto"
    "footer-grid footer-grid";
}
.grid-container-articulo-invertido {
  width: 90%;
  height: 80%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 70% 1fr;
  grid-template-areas:
    "foto titulo"
    "footer-grid footer-grid";
}
.titulo {
  grid-area: titulo;
  display: flex;
  
  /* flex-direction: column-reverse; */
}
.titulo img{
  width: auto;
 
}
.foto {
  grid-area: foto;
}
.footer-grid {
  grid-area: footer-grid;
}
.grid-container-articulo .grid-item-articulo:nth-child(3) {
  grid-column: 1/3;
}
.grid-item-articulo {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.grid-item-articulo h1 {
  font-size: 4.5rem;
  text-align: center;
  font-family: "Montserrat Alternates", sans-serif;
  font-weight: 800;
  font-style: normal;
}
.reportero {
  height: 90%;
  width: auto;
  border-radius: 20px;
  position: absolute;
  top: 0;
}
.grid-container-proceso {
  display: flex;
  border: solid rgba(43, 43, 43, 0.375) 1px;
  box-shadow: 0 0 3px 3px rgba(0, 0, 0, 0.358);
  /* grid-template-columns: 20% 1fr 20% 1fr 20% 1fr 20%; */
}
.grid-container-proceso div {
  height: 100%;
}
.grid-container-proceso div:nth-child(1),
.grid-container-proceso div:nth-child(3),
.grid-container-proceso div:nth-child(5),
.grid-container-proceso div:nth-child(7) {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 20%;
  margin: auto;
  padding: 10px;
}

.grid-container-proceso div .etapa-reportaje {
  height: 70%;
  width: 100%;
  border-radius: 50px;
  margin: 0;
  display: flex;
  overflow: hidden;
}
.etapa-reportaje img {
  max-width: 25%;
  width: 25%;
  min-width: 20%;
  height: auto;
}
.etapa-reportaje h6 {
  margin: 0;
  flex: 1;
  color: white;
  text-align: center;
}
.flecha {
  height: 30%;
  width: auto;
  margin: auto;
}

/* movil pequeño */
@media (max-width: 575px) {
  .footer-grid,.grid-container-articulo,.grid-container-articulo-invertido {
    display: none;
  }
  .articulo{
    height: auto;
  }
  .card{
    margin: 10% auto;
  }
  
}
/* movil grande */

@media (min-width:575px) and (max-width:767px) {
  .footer-grid,.grid-container-articulo,.grid-container-articulo-invertido {
    display: none;
  }
  .articulo{
    height: auto;
  }
  .card{
    margin: 10% auto;
    width: 80% !important;
  }
  
  
}
/* tabletas */

@media (min-width:767px) and (max-width:1024px) {
  .footer-grid,.grid-container-articulo,.grid-container-articulo-invertido {
    display: none;
  }
  .articulo{
    height: auto;
  }
  .card{
    margin: 10% auto;
    width: 80% !important;
  }
}
/* responsive especial */
@media (min-width:1024px)and (max-width:1220px) {
  .titulo{
    flex-direction: column;
  }
}