.container-servicios {
  margin-top: 20vh;
  height: auto;
  width: 100vw;
  box-sizing: border-box;
}
.container-articulo-reportajes {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
}
.articulo-reportajes {
  width: 100%;
  height: 130vh;
  background-color: #d2eac3;
  display: flex;
  flex-direction: column;
}
.container-reportajes {
  height: 70%;
  width: 90%;
  position: absolute;
  bottom: 100px;
  display: flex;
}
.flex-repo {
  flex: 1;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.reportajes-animacion {
  font-family: "Montserrat Alternates", sans-serif;
  font-weight: 800;
  font-style: normal;
  font-size: 7rem;
  position: absolute;
  top: 30%;
}
.container-img-reportajes img {
  width: 80%;
  height: auto;
  border-radius: 20px;
}
.articulo-reportajes p,
.articulo-guia p {
  flex: 1;
  margin: 0;
  text-align: center;
  font-size: 2.3rem;
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 200;
  font-style: normal;
}
.articulo-guia p,
.articulo-guia .reportajes-animacion {
  color: #ffffff;
}
.articulo-guia {
  height: 150vh;
  width: 100%;
  background-color: #508330;
}
.invert {
  flex-direction: row-reverse;
}
.ski {
  background-color: rgb(15, 145, 136);
  color: #ffffff;
  height: 100vh;
}
.ski .reportajes-animacion {
  top: 15%;
  text-align: center;
}
.ski button {
  background-color: #0f9188;
  height: min-content;
  border: 2px outset white;
  border-radius: 20px;
  padding: 10px 30px;
  font-size: 1.5rem;
  color: rgb(176, 176, 176);
  margin-bottom: 3%;
}
.ski button:hover {
  background-color: #95959584;
  color: rgb(255, 255, 255);
  transition: 0.2s;
}
.container-btn-plan {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.container-btn-plan h4 {
  color: #d9dbd8;
  font-size: 3rem;
  margin: 3%;
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 200;
  font-style: normal;
}
.directorio .reportajes-animacion {
  font-size: 5rem;
  top: 15%;
  text-align: center;
}
.directorio {
  height: 130vh;
}
.plan {
  background-color: rgba(34, 123, 195, 0.974);
  position: fixed;
  top: 20vh;
  width: 100%;
  height: 60vh;
  z-index: 50;
  display: flex;
  flex-direction: column;
  border: #064f87 5px solid;
}

.plan h1 {
  text-align: center;
  padding: 3% 0;
  margin: 0;
  position: relative;
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
  font-size: 3rem;
}
.plan h1 span {
  position: absolute;
  right: 5%;
  top: 10%;
}
.plan h1 span:hover {
  color: #000000;
  cursor: pointer;
}
.container-promo {
  flex: 1;
  display: flex;
}
.caja-flex {
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}
.caja-flex h1 {
  padding: 0;
  font-weight: 600;
  color: yellow;
  font-size: 1.7rem;
}
.caja-flex h2 {
  display: block;
  margin: 0;
  text-align: center;
  color: rgb(147, 145, 145);
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
    1px 1px 0 #000;
  font-size: 1.4rem;
}
.caja-flex ul {
  display: flex;
  flex-direction: column;
  flex: 0.7;
  margin: auto 0;
}
.caja-flex ul li {
  flex: 1;
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
}
.caja-flex video{
  height: 250px;
  width: min-content;
  border: #064f87 4px solid;
  border-radius: 20px;
  position: absolute;
  right: 0
}
.titulo-video{
  text-align: center;
  font-size: 2rem;
  margin: 10% 0;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-style: normal;
  color: rgb(11, 33, 90);
}
.video-ski{
  width: 80%;
  margin: 0 auto;
  margin-bottom: 10%;
  height: auto;
  border: solid rgb(11, 33, 90) 10px;
 border-radius: 20px;
}
.container-btn-video{
  width: 100%;
  display: flex;
  justify-content: space-around;
  margin: auto;
  
}
.container-btn-video button{
  border: solid rgb(11, 33, 90) 3px;
  border-radius: 50px;
  font-size: 1rem;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-style: normal;
  color: #064f87;
  margin: 5px;
  padding: 2%;
}
.container-btn-video button a{
  color: #064f87;
}
.unete{
  position: absolute;
  z-index: 1000;
  background-color: red;
  color: white;
  padding: 3% 5%;
  clip-path: polygon(0% 15%, 15% 15%, 15% 0%, 85% 0%, 85% 15%, 100% 15%, 100% 85%, 85% 85%, 85% 100%, 15% 100%, 15% 85%, 0% 85%);
  transform: rotate(25deg) translateX(30%);
  right: 7%;
  top: 0;
  font-size: 1rem;
}
@media (max-width:575px) {
  .titulo-video{
    font-size: 1.5rem;
  }
}