.container-descubre {
  height: 230vh;
  width: 100vw;
  /* background: linear-gradient(to top, #849b4e, #fbd533 4%, orange 90%); */
  background-color: orange;
  box-sizing: border-box;
}
.container-titulos {
  height: 30vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  position: relative;
}
.container-titulos img {
  position: absolute;
  height: 40%;
  width: auto;
}
.cabañas {
  top: 50%;
  right: 15%;
}
.ski_icono {
  top: 40%;
  left: 10%;
}

.container-titulos h1 {
  margin: 0;
  padding-top: 2%;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Montserrat Alternates", sans-serif;
  font-weight: 800;
  font-style: normal;
  font-size: 5.62rem;
  color: rgb(43, 39, 35);
}
.container-titulos h2 {
  margin: 0;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2.3rem;
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
}
.grid-container {
  height: 90%;
  width: 90%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr;
  gap: 3%;
}
.container-grid-container {
  height: 200vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}
.grid-item-navlink {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  transition: 0.2s ease-in-out;
  border-radius: 20px;
  overflow: hidden;
}
.grid-item-navlink:hover {
  scale: 1.1;
  z-index: 50;
  cursor: pointer;
}

.grid-item {
  height: 0;
  width: 0;
  margin: 0;
  box-sizing: border-box;
  position: relative;
  /* border-radius: 20px; */
  overflow: hidden;
}
.grid-item.active {
  height: 100%;
  width: 100%;
  transition: 1s;
}
.foto-figure {
  height: 100%;
  width: 100%;
  position: absolute;
  scale: 1;
  transition: 0.2s ease-in-out;
}
.foto-figure:hover {
  opacity: 0.15;
  scale: 1.1;
}
.figcaption {
  background-color: black;
  height: 100%;
  width: 100%;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 3rem;
  font-family: "Spicy Rice", serif;
  font-weight: 400;
  font-style: normal;
}
.h1-responsive {
  display: none;
}

/* RESPONSIVE */
@media (max-width: 767px) {
  .container-descubre {
    height: auto;
    display: none;
  }
}
/* tabletas */
@media (min-width: 767px) and (max-width: 1024px) {
  .container-descubre {
    display: none;
  }
}

/* arreglin icono cabaña descubre */
@media (max-width: 1293px) {
  .cabañas {
    display: none;
  }
}
