.container-footer {
  height: 100%;
  width: 100%;
  background-color: orange;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
}
.container-redes-sociales {
  height: 30%;
  width: 100%;
  display: flex;
  position: relative;
}
.hr-footer {
  height: 1px;
  background: radial-gradient(
    ellipse farthest-corner at 50% 50%,
    red,
    yellow,
    green,
    blue
  );

  border: none;
  margin: 3.3% 0 3.3% 0;
  width: 70%;
}
.container-footer h5 {
  height: min-content;
  margin: 0;
  font-size: 2.5rem;
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;

}
.creada-con{
  position: absolute;
  height: min-content;
  width: auto;
  top: 3%;
  left: 15%;
  margin: 0;
  font-size: 1.4rem;
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 200;
  font-style: italic;
}
.container-iconos{
  position: absolute;
  top: 3%;
  height: min-content;
  width: min-content;
  right: 20%;
  display: flex;
}
.creada-con img{
  height: 30px;
  width: auto;
}
.container-iconos a img{
  height: 50px;
  width: auto;
  margin: 0 25px;
}
/* RESPONSIVE */
/* movil grande y pequeño */
@media (max-width:767px) {
  .container-footer{
    padding: 5%;
  }
  .container-footer h5{
    font-size: 1.5rem;
  }
  .container-redes-sociales{
    height: auto;
  }
  .creada-con{
    position: static;
    width: 60%;
    font-size: 90%;
    text-align: center;
  }
  .container-iconos{
    position: static;
    flex: 1;
    display: flex;
    justify-content: space-around;
  }
  .container-iconos a img{
    margin: 0;
  }
}
/* tabletas */
@media (min-width:767px) and (max-width:1024px) {
  .container-footer h5{
    font-size: 1.7rem;
  }

}