.container-hero {
  height: 100vh;
  width: 100%;
  box-sizing: border-box;
  background-image: url("../../Multimedia/FondoHero.webp");
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
}
.titulo-hero {
  margin: 0 0 0 35px;
  position: relative;
  bottom: 100px;
  font-family: "Montserrat Alternates", sans-serif;
  font-weight: 800;
  font-style: normal;
  font-size: 90px;
  width: 900px;
  color: rgb(43, 39, 35);
}
/* RESPONSIVE */
/* movil grande y pequeño */
@media (max-width: 767px) {
  .container-hero {
    background-image: url("../../Multimedia/volcan_responsive.jpg");
    background-position: center center;
    justify-content: right;
  }
  .titulo-hero {
    margin: 0;
    top: 5%;
    font-family: "Montserrat Alternates", sans-serif;
    font-weight: 800;
    font-style: normal;
    font-size: 3rem;
    width:auto;
    max-width: 100vw;
    text-align: right;
    backdrop-filter: blur(5px);
    border: rgba(203, 203, 195, 0.394) 2px solid;
    border-radius: 10px;
    padding-right:5% ;
  }
}
/* tabletas */
@media (min-width:767px) and (max-width:1024px) {
  .container-hero{
    position: relative;
  }
  .titulo-hero{
    margin: 0;
    padding-left: 3%;
    position: absolute;
    top: 30%;
    height: min-content;
    width: 100%;
    max-width: 100%;
    font-size: 3rem;
    text-align: left;
  }
  
}