.container-carrusel-descubre {
  height: 100vh;
  width: 100vw;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.505);
  backdrop-filter: blur(5px);
  top: 0;
  z-index: 2000;
}
.container-relative {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.carrusel-descubre {
  height: 80%;
  width: 60%;
  border-radius: 10px;
  overflow: hidden;
}
.btn-close-carrusel {
  position: absolute;
  right: 10%;
  bottom: 10%;
  height: 10%;
  width: 50px;
  height: min-content;
  width: min-content;
  font-size: 6rem;
  color: rgba(128, 128, 128, 0.841);
}
.btn-close-carrusel:hover {
  color: yellow;
  cursor: pointer;
}
/* .foto-carrusel {
  width: 100%;
  height: 582.075px;
} */
.carousel-item {
  height: 80%;
}
.carousel-caption {
  backdrop-filter: blur(2px);  
  right: 0;
  left: 0;
}
.foto-carrusel {
  width: 100%;
  height: 100%;
}
.carousel-caption::before {
  content: "";
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0.4;
  background-color: rgb(0, 0, 0);
  cursor: pointer;
}
.carousel-indicators {
  display: none;
}
.deporte-carrusel {
  font-size: 4rem;
  color: rgb(241, 241, 241);
  font-family: "Spicy Rice", serif;
  font-weight: 400;
  font-style: normal;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
    1px 1px 0 #000;
}

.visually-hidden {
  display: none;
}
span.carousel-control-next-icon,
span.carousel-control-prev-icon {
  height: 50%;
  width: 50%;
}

@media (max-width: 1024px) {
  .container-carrusel-descubre {
    display: none;
  }
}
@media (min-width: 1024px) {
}
