.container-actividades {
  height: auto;
  width: 100vw;
  background-color: #b5d1a1;
  box-sizing: border-box;
  clip-path: circle(77.4% at 32% 30.5%);
  padding-top: 40px;
}
.container-articulos {
  width: 100%;
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.articulos {
  width: 90%;
  height: 80%;
  position: relative;
}
#sur-de-chile {
  font-family: "Montserrat Alternates", sans-serif;
  font-weight: 800;
  font-style: normal;
  font-size: 5.64rem;
  color: rgb(43, 39, 35);
  position: absolute;
  right: -100px;
  top: 0;
  margin: 50px 0;
  transition: 0.5s ease-out;
  text-shadow: -1px -1px 0 rgb(255, 208, 0), 1px -1px 0 #000, -1px 1px 0 #000,
    1px 1px 0 #000;
}
#sur-de-chile.activa {
  position: absolute;
  right: 15%;
  top: 0;
}
#termas {
  font-family: "Montserrat Alternates", sans-serif;
  font-weight: 800;
  font-style: normal;
  font-size: 90px;
  color: rgb(43, 39, 35);
  position: absolute;
  top: 0%;
  left: 10%;
  transition: 0.5s ease-out;
}
#estilo {
  font-family: "Montserrat Alternates", sans-serif;
  font-weight: 800;
  font-style: normal;
  font-size: 90px;
  color: rgb(43, 39, 35);
  position: absolute;
  right: 15%;
  top: 0;
  margin: 50px 0;
  transition: 0.5s ease-out;
}
.img-volcan {
  position: absolute;
  left: 10%;
  bottom: 10%;
  height: 90%;
  width: auto;
  border-radius: 20px;
}
.img-termas {
  position: absolute;
  right: 5%;
  width: auto;
  height: 70%;
  border-radius: 20px;
}
.parrafo-surdechile.activa {
  position: absolute;
  right: 11.5%;
  top: 35%;
  width: 45%;
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  color: rgb(43, 39, 35);
  transition: 0.6s ease-out;
}
.parrafo-surdechile {
  position: absolute;
  right: 11.5%;
  font-weight: 600;

  top: 100%;
  width: 45%;
  font-size: 36px;
  font-style: italic;
  color: transparent;
}
.parrafo-termas {
  position: absolute;
  left: 9%;
  top: 25%;
  height: auto;
  width: 40%;
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
  font-size: 36px;
  color: rgb(43, 39, 35);
}
.parrafo-estilo {
  position: absolute;
  right: 5%;
  top: 30%;
  height: auto;
  width: 40%;
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
  font-size: 36px;
  color: rgb(43, 39, 35);
}
.articulos h5 {
  font-family: "Montserrat Alternates", sans-serif;
  font-weight: 800;
  font-style: normal;
  position: absolute;
  font-size: 1.5rem;
  top: -10px;
  left: -10px;
}
.separador {
  width: 80%;
  height: 3px;
  background: radial-gradient(rgb(34, 16, 2) 1%, #849b4e);
  margin: 0 auto;
}
/* RESPONSIVE */
/* movil grande y pequeño */
@media (max-width: 767px) {
  /* desactivadores */
  .articulos h5,
  .parrafo-surdechile,
  .parrafo-estilo,
  .parrafo-termas {
    display: none;
  }
  .container-actividades {
    padding-bottom: 20%;
    clip-path: circle(93.4% at 32% 30.5%);
  }
  .container-articulos {
    height: auto;
  }
  .separador {
    margin: 10% auto;
  }
  #sur-de-chile {
    font-family: "Montserrat Alternates", sans-serif;
    font-weight: 800;
    font-style: normal;
    font-size: 4rem;
    color: rgb(43, 39, 35);
    position: static;
    text-shadow: -1px -1px 0 rgb(255, 208, 0), 1px -1px 0 #000, -1px 1px 0 #000,
      1px 1px 0 #000;
  }
  #sur-de-chile.activa {
    position: static;
    text-align: center;
    margin: 5%;
  }

  .img-volcan {
    height: 60vh;
    width: auto;
    max-width: 80vw;
    position: static;
  }
  .articulos {
    height: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  #termas {
    font-size: 4rem;
    position: static;
    margin: 0;
    margin: 5%;
  }
  .img-termas {
    position: static;
    width: 100%;
    height: auto;
  }
  #estilo {
    position: static;
    margin: 0;
    font-size: 4rem;
    margin: 5%;
  }
}
/* tabletas */
@media (min-width: 767px) and (max-width: 1024px) {
  .articulos h5,
  .parrafo-termas {
    display: none;
  }
  .container-actividades {
    padding-bottom: 20%;
    clip-path: circle(83.4% at 32% 30.5%);
  }
  .container-articulos {
    height: auto;
  }
  .separador {
    margin: 10% auto;
  }
  #sur-de-chile {
    font-family: "Montserrat Alternates", sans-serif;
    font-weight: 800;
    font-style: normal;
    font-size: 4rem;
    color: rgb(43, 39, 35);
    position: static;
    margin: 5% auto;
    text-shadow: -1px -1px 0 #9ea7ba, 1px -1px 0 #000, -1px 1px 0 #000,
      1px 1px 0 #000;
  }
  #sur-de-chile.activa {
    position: static;
  }

  .img-volcan {
    height: 60vh;
    width: auto;
    max-width: 80vw;
    position: static;
    margin-left: 10%;
    margin-top: 3%;
    border: solid rgb(43, 39, 35) 3px;
  }
  .articulos {
    height: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  #termas {
    font-size: 5rem;
    position: static;
    margin: 0;
  }
  .img-termas {
    width: 60%;
    height: auto;
    position: absolute;
    left: 30%;
  }
  #estilo {
    position: static;
    margin: 0;
  }
  .container-img-parrafo {
    display: flex;
    width: 100%;
    height: 100%;
  }

  .parrafo-surdechile {
    right: 11%;
    width: 45%;
    font-size: 1.6rem;
  }
  .parrafo-estilo {
    font-size: 1.6rem;
  }
  .devolver-termas {
    display: none;
  }
}

/* arreglin de componente sur extra */
@media (min-width: 1025px) and (max-width: 1500px) {
  .container-actividades {
    clip-path: circle(89.4% at 42% 26.5%);
  }
}
@media (min-width: 1410px) and (max-width: 1500px) {
  .container-actividades {
    clip-path: circle(79.4% at 42% 26.5%);
  }
}
/* arreglin animacion 'sur de chile.active' */

@media (min-width: 1025px) and (max-width: 1338px) {
  #sur-de-chile.activa {
    position: absolute;
    right: 7%;
    font-size: 4.2rem;
    top: 0;
  }
  #termas {
    left: 5%;
    font-size: 4.2rem;
  }
  .parrafo-termas {
    left: 5%;
  }
  .img-termas {
    right: 0;
  }
}
