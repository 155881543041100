.container-formulario {
  width: 95%;
  margin: auto;
}

.form-group {
  display: flex;
  flex-direction: column;
}
.container-formulario h1 {
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-style: normal;
}
.container-formulario h3 {
  text-align: center;
  font-size: 1rem;
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-style: normal;
}
.form-group label,
.form-exitoso button,.form-exitoso h5{
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-style: normal;
}
.form-group input,
.form-group textarea {
  width: 95%;
  margin: 0 2.5%;
}
.form-btns {
  border: solid #19508f 3px;
  border-radius: 20px;
  padding: 10px 30px;
  color: #19508f;
  margin: 1% 5%;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-style: normal;
}
.form-exitoso {
  display: none;
  overflow-x: hidden;
}
.form-exitoso h1 ,.form-exitoso h5 span{
  color: #19508f;
}
.form-exitoso h5 {
  text-align: center;
  margin: 4% auto;
}
.form-exitoso button {
  margin: 0 50%;
  transform: translateX(-50%);
  border: solid #19508f 3px;
  border-radius: 20px;
  padding: 10px 30px;
  color: #19508f;
}
.form-error{
  color: red;
}
