.card {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  overflow: hidden;
  display: none;
}

.list-group-item {
  display: flex;
  justify-content: space-between;
  
}

.list-group-item h6{
    margin: 0;
    font-family: montserrat;
    font-weight: 500
}
.btn-ski{
    display: none;
    border-radius: 10px;
    padding:2% 5%;
    border: rgb(5, 5, 91) 2px solid;
    color: rgb(10, 10, 79);
    font-family: montserrat;
    font-weight: 600;
}

.container-btn-ski{
    display: flex;
    justify-content: center;
    align-items: center;
}
/* movil pequeño */
@media (max-width:575px) {
    .card{
        display: block;
    }
}
/* movil grande */

@media (min-width:575px) and (max-width:767px) {
    .card{
        display: block;
    }
    .card-title{
        text-align: center;
        font-size: 2.5rem;
    }
    .list-group-item h6{
        font-size: 1.2rem;
    }
}
/* tableta */

@media (min-width:767px) and (max-width:1024px) {
    .card{
        display: block;
    }
    .card-title{
        text-align: center;
        font-size: 2.5rem;
    }
    .list-group-item h6{
        font-size: 1.2rem;
    }
  }