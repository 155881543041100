#dropdown-basic {
  background-color: transparent;
  border: none;
  position: absolute;
  right: 5%;
}
.dropdown {
  display: none;
  justify-content: flex-end;
  align-items: center;
}
.dropdown-menu {
  backdrop-filter: blur(10px);
  background: rgba(27, 31, 42, 0.968);

  height: 80vh;
  width: 100vw;
  text-align: center;
  padding-top: 4%;
}

/* styles.css */
.dropdown-toggle:focus {
  box-shadow: none !important;
  outline: none !important;
}
.dropdown-toggle::after {
  display: none;
}
.hr-menuchico {
  width: 90%;
  background-color: rgb(47, 46, 42);
  height: 2px;
}
@media (max-width: 575px) {
}

/* moviles grandes y pequeños */
@media (max-width: 767px) {
  .dropdown {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .texto-menu {
    font-family: montserrat;
    color: rgb(202, 195, 170);
    font-size: 2rem;
  }
}
/* tabletas */
@media (min-width: 767px) and (max-width: 1024px) {
  .dropdown {
    display: flex;
  }
  .texto-menu {
    font-family: montserrat;
    color: rgb(202, 195, 170);
    font-size: 2rem;
  }
}
